import { capitalize } from 'vue';

import type {
  Bidder,
  Sale,
  Vacation,
} from '~/types/artcurial/artcurial-domain';
import type { SeoMeta } from '~/types/cms/common';

interface SaleJoinInfo {
  label: string;
  to: string;
}

interface UseSaleResultUndefined {
  canDisplayBidLimit: ComputedRef<boolean>;
  hasNotStarted: ComputedRef<boolean>;
  isFinished: ComputedRef<boolean>;
  isOngoing: ComputedRef<boolean>;
  isOnline: ComputedRef<boolean>;
  ongoingVacation: ComputedRef<Vacation | undefined>;
  saleJoinInfo: ComputedRef<SaleJoinInfo | undefined>;
  seo: ComputedRef<SeoMeta>;
  userDirections: ComputedRef<Array<string> | undefined>;
}

interface UseSaleResult {
  canDisplayBidLimit: ComputedRef<boolean>;
  hasNotStarted: ComputedRef<boolean>;
  isFinished: ComputedRef<boolean>;
  isOngoing: ComputedRef<boolean>;
  isOnline: ComputedRef<boolean>;
  ongoingVacation: ComputedRef<Vacation | undefined>;
  saleJoinInfo: ComputedRef<SaleJoinInfo | undefined>;
  seo: ComputedRef<SeoMeta>;
  userDirections: ComputedRef<Array<string> | undefined>;
}

export function useSale(
  sale: Ref<Sale>,
  bidder?: Ref<Bidder | undefined>
): UseSaleResult;
export function useSale(
  sale: Ref<Sale | undefined>,
  bidder?: Ref<Bidder | undefined>
): UseSaleResultUndefined;

/**
 * Get the computed properties for a given sale
 * @param sale The sale to get the properties from
 * @param bidder The bidder to get the properties from
 * @returns The computed properties for the sale
 */
export function useSale(
  sale: Ref<Sale | undefined>,
  bidder: Ref<Bidder | undefined> = ref(undefined)
): UseSaleResultUndefined {
  const { t } = useI18n();
  const ogImg = useImage();

  const seo = computed<SeoMeta>(() => {
    if (!sale.value) {
      // Default SEO values
      return {
        description: t('sales.seo.default.description'),
        ogDescription: t('sales.seo.default.description'),
        ogImage: ogImg('/artcurial-default_image.png', { width: 300 }),
        ogTitle: t('sales.seo.default.title'),
      };
    }

    return {
      description: t('sales.seo.description', {
        sale: capitalize(sale.value.name),
      }),
      ogDescription: t('sales.seo.description', {
        sale: capitalize(sale.value.name),
      }),
      ogImage: ogImg(
        sale.value?.picture?.servingUrl || '/artcurial-default_image.png',
        { width: 300 }
      ),
      ogTitle: capitalize(sale.value.name),
    };
  });

  const bidderIsRegistered = computed(() => {
    return bidder.value !== undefined;
  });

  const hasNotStarted = computed(() => {
    if (!sale.value) return false;
    return sale.value.status === 'COMPLETE';
  });

  const isOngoing = computed(() => {
    if (!sale.value) return false;
    return sale.value.status === 'ON_GOING';
  });

  const isFinished = computed(() => {
    if (!sale.value) return false;
    return sale.value.status === 'FINISHED';
  });

  const isOnline = computed(() => {
    if (!sale.value) return false;
    return sale.value.channel === 'ONLINE_ONLY';
  });

  const ongoingVacation = computed(() => {
    if (!sale.value || !isOngoing) return undefined;
    return sale.value.vacations.find((vac) => vac.status === 'ON_GOING');
  });

  const saleJoinInfo = computed<SaleJoinInfo | undefined>(() => {
    if (!sale.value) return undefined;

    if (isOngoing.value && !isOnline.value) {
      return {
        label: t('sales.joiningSale'),
        to: `/en/sales/${sale.value.ref}/auction`,
      };
    }
    if (
      ['COMPLETE', 'ON_GOING'].includes(sale.value.status) &&
      sale.value.openRegistration &&
      !bidderIsRegistered.value
    ) {
      return {
        label: t('sales.inscriptionToSale'),
        to: `/en/sales/${sale.value.ref}/registration`,
      };
    }

    return undefined;
  });

  const userDirections = computed(() => {
    if (!sale.value) return undefined;

    if (
      !bidder.value &&
      sale.value.channel === 'PUBLIC' &&
      sale.value.status === 'ON_GOING'
    )
      return [t('bidder.direction.ongoingSale')];

    if (!bidder.value) return undefined;

    if (bidder.value.type === 'ROOM' && sale.value.status === 'COMPLETE')
      return [t('bidder.direction.room')];
    if (bidder.value.type === 'ABSENTEE' && sale.value.status === 'COMPLETE')
      return [
        t('bidder.direction.absentee.firstLine'),
        t('bidder.direction.absentee.secondLine'),
      ];
    if (bidder.value.type === 'INTERNET' && sale.value.status === 'COMPLETE')
      return [t('bidder.direction.internet')];
    if (bidder.value.type === 'PHONE' && sale.value.status === 'COMPLETE')
      return [
        t('bidder.direction.phone.firstLine'),
        t('bidder.direction.phone.secondLine', {
          number: bidder.value.selectedPhone
            ? `${bidder.value.selectedPhone.prefix} ${bidder.value.selectedPhone.number}`
            : '',
        }),
      ];
    return undefined;
  });

  const canDisplayBidLimit = computed(() => {
    if (!sale.value || !bidder.value) return false;
    return bidder.value.type === 'ABSENTEE' || bidder.value.type === 'INTERNET';
  });

  return {
    canDisplayBidLimit,
    hasNotStarted,
    isFinished,
    isOngoing,
    isOnline,
    ongoingVacation,
    saleJoinInfo,
    seo,
    userDirections,
  };
}
