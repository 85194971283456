/**
 *
 * @param val
 * @param country
 */
export function localizeCurrency(val: number, country?: string) {
  const { n } = useI18n();
  if (isNaN(Number(val))) {
    throw new Error(`${val} is not a number`);
  }
  const currency = country === 'ma' ? 'madCurrency' : 'currency';
  return n(Number(val), currency);
}

/**
 *
 * @param country
 */
export function localizeCurrencySymbol(country?: string) {
  return country === 'ma' ? 'MAD' : '€';
}
